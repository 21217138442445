import Header from "../components/Header";
import Footer from "../components/Footer";
import React from 'react';

const DutiesandTaxesFaq = () => {
    return ( 
    <React.Fragment>
          <Header name={{ name: 'Advantis Express' , group:'home' }}   />
          <div className="contentswrap">
            <section className="banner-wrapper" style={{backgroundImage: `url(${"assets/img/faq-banner.png"})`,}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="banner-title">
                            <h1>Frequently asked questions</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <div className="breadcrumb-wrap faq2">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page"><a href="/faq">FAQ</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Duties and Taxes</li>
                            </ol>
                        </nav>
                        <h2 className="faqtitle">Common questions asked by customers</h2>
                    </div>
                </div>
                </div>
                <div className="row">
                <div className="col-md-4">
                    {/* <!-- mobile search --> */}
                    <div className="faq-search-mobile">
                        <div className="row">
                            <div className="col-12">
                                <div className="search-form">
                                    <input type="text" className="form-control" placeholder="Search your question"></input>
                                    <button type="submit" className="btn-search"><img src="assets/img/search-icon.png" alt=""></img> </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- mobile search end --> */}
                    <div className="faq-sidebar">                     
                        <div className="faqwrapper">
                            <div className="faq-link-wrap" data-aos="fade-down">
                            <h2>Duties and Taxes</h2>
                            <ul>
                                <li> <a href="#f1">How are the Duty and Tax charges calculated?</a></li>
                                <li> <a href="#f2">The package was a gift, why have I been<br></br> charged?</a></li>
                                <li> <a href="#f3">I purchased these goods on the Internet,<br></br>why have I been charged?</a></li>
                                <li> <a href="#f4">The goods were second hand, why have I been charged?</a></li>
                                <li> <a href="#f5">I paid tax when I bought this item, why do I have to pay more?</a></li>
                                <li> <a href="#f5">I believe the shipper paid for all charges relating to this shipment?</a></li>
                                <li> <a href="#f5">I pre-paid duty/tax charges before my goods were <br></br>delivered, do I have to pay more?</a></li>
                                <li> <a href="#f5">I am a temporary visitor to Sri Lanka, do I still have<br></br> to pay import charges?</a></li>
                                <li> <a href="#f5">Do I have to pay for samples?</a></li>
                                <li> <a href="#f5">I want to reject this shipment how do I do this?</a></li>
                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="faq-content-col">
                        <div className="faq-search-wrap">
                        <div className="faq-search-dk">
                            <div className="row">
                                    <div className="col-12">
                                        <div className="search-form">
                                        <input type="text" className="form-control" placeholder="Search your question"></input>
                                        <button type="submit" className="btn-search"><img src="assets/img/search-icon.png" alt=""></img></button>
                                        </div>
                                    </div>
                            </div>
                        </div>                     
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="search-contents faqlist" data-aos="fade-down">
                                        <h2>Duties and Taxes</h2>  
                                        <div className="quick-faq">
                                        <ul>
                                            <li><a href=""style={{color:'#4D148C',fontSize: 20}}>How are the Duty and Tax charges calculated?</a></li>
                                            <p>When sending goods to Sri Lanka the shipper must provide a commercial invoice declaring the value of the goods. This value is then converted into Sri Lankan Rupees by dividing it by the appropriate exchange rate, which is set by Inland Revenue and Customs. The costs for transporting the goods to the Sri Lankan border, insurance and any other applicable costs are subsequently added to the value of the goods. The resulting sum is referred to as the Customs Value. Customs Duty is assessed on the Customs  Value, at the appropriate rate for the declared commodity. Additionally, certain commodities will be subject to other charges such as excise duty, taxes and levies. The Duties and other charges (if applicable), the remaining cost of transport from the Sri Lanka Border to the consignee’s premises and a VAT Value Adjustment amount, which is periodically agreed with Customs, are then added to the Customs value. The resulting total provides the VAT values. VAT is calculated on this figure at the current rate at the time of release. To review the calculations, please refer to the Supplementary Declaration, which accompanies our invoice.</p>
                                                <br></br>
                                            <li><a href=""style={{color:'#4D148C',fontSize: 20}}>The package was a gift. Why have I been charged? </a></li>
                                            <p>Your package must meet the Customs gift criteria: <br></br>
                                                1. It must be sent directly from person to person (the sender must be a private individual and consignee must take delivery at a private address). If the package is shipped by a third party directly rather than by the sender then the gift criteria are not met 
                                                <br></br>
                                                2. The total value must not exceed 15,000 LKR per person 
                                                <br></br>
                                                3. Ensure the word “GIFT” is marked on the Air Waybill and commercial invoice 
                                                <br></br>
                                                4. The gift must be sent free of charge and be of an ‘occasional nature’ 
                                                <br></br>
                                                5. Items containing alcohol and tobacco will incur Excise Duty
                                                <br></br>
                                                6. Items containing food may still need to have additional documentation and be subject to further checks which could give rise to additional charges 
                                                <br></br>   
                                                7. All items must be itemised with their own full description and value. 
                                                Note that Sri Lanka Customs makes the final decision on all import classifications. For Customs gift criteria please visit http://www.customs.gov.lk/ as these criteria are subject to change
</p>
                                            <li><a href="" style={{color:'#4D148C',fontSize: 20}}>I purchased these goods on the Internet. Why have I been charged?</a> </li>
                                            <p>Duty and Tax may be levied on shipments coming from outside of Sri Lanka. If you purchase products via the Internet, you must make sure you read the terms and conditions set by your shipper. In the majority of sales, the consignee is
                                                responsible for paying import charges. The majority of companies today advise their customers of possible local import charges at the time of the sale.</p>
                                            <li><a href=""style={{color:'#4D148C',fontSize: 20}}>The goods were second hand. Why have I been charged? </a></li>
                                            <p>Sri Lanka Customs assess Duty/Tax charges based on whether the goods are entering Sri Lanka and give rise to a Duty/Tax liability, regardless
                                                 of age or previous ownership. If you purchase antiques or original artwork there is a reduced rate of VAT where the goods meet the correct criteria.</p>
                                            <li><a href=""style={{color:'#4D148C',fontSize: 20}}>I paid tax when I bought this item. Why do I have to pay more?</a></li>
                                            <p>Goods purchased from outside of Sri Lanka may be subject to tax charges from the originating country and the shipper may charge you 
                                                for this. If this is the case, Sri Lanka Customs import charges will still be levied when the goods enter the Sri Lanka, as this is a separate charge. </p>
                                            <li><a href=""style={{color:'#4D148C',fontSize: 20}}>The shipper claims to have paid all charges relating to the shipment. Do I have to pay more?</a></li>
                                            <p>Duty/Tax charges are not assessed until the shipment is entering Sri Lanka. If your shipper has assured you they 
                                                have paid FedEx for these charges, in the majority of cases the charges they have paid relate to the transportation charges and not Duty/Tax charges.</p>
                                            <li><a href=""style={{color:'#4D148C',fontSize: 20}}>I pre-paid duty/tax charges before my goods were delivered, do I have to pay more?</a></li>
                                            <p>If you were contacted by FedEx prior to delivery and made pre-payment of import charges then there may
                                                 be more to pay. Equally, if you have over paid, FedEx will refund the difference. The reason for the difference is that at the time of import you are charged an estimated figure. This figure is subject to change as it is calculated prior to your shipment being assessed by Sri Lanka and Customs. If the amount on this invoice 
                                                 is different to the amount you pre-paid then please call FedEx on 0094114522222. Please note that Sri Lanka Customs make the final decision on all import charges.</p>
                                            <li><a href=""style={{color:'#4D148C',fontSize: 20}}>I am a temporary visitor to Sri Lanka. Do I still have to pay import charges?</a></li>
                                            <p>If you are a temporary visitor, then please call FedEx on 0094114522222. You will 
                                                need to pay the import charges, but you may be entitled to claim the charges back when you leave Sri Lanka depending on the individual circumstances.</p>
                                            <li><a href=""style={{color:'#4D148C',fontSize: 20}}>Do I have to pay for samples?</a></li>
                                            <p>Samples can be imported into Sri Lanka without Duty/Tax charges being levied if they meet all the following Customs conditions: 
                                                <br></br>
                                                1. Can only be used as samples. 
                                                <br></br>
                                                2. Are of negligible value. 
                                                <br></br>
                                                3. Intended to obtain orders for the type of goods represented. 
                                                <br></br>
                                                The commercial invoice and any other paperwork provided with the shipment must declare this information to enable Customs to make the appropriate entry. Trade samples can be cleared under No Foreign Exchange (NFE) basis after obtaining the approval from the Director/Deputy Director (Declaration). But, the relevant duty and other levies for the trade sample should be paid to Customs before such goods are cleared from the customs.</p>
                                            <li><a href=""style={{color:'#4D148C',fontSize: 20}}>I want to reject a shipment. How do I do this?</a></li>
                                            <p>To be classed as a rejected import your shipment must comply with the following Sri Lanka and Customs criteria: 
                                               <br></br>
                                                • The goods are defective, were damaged before clearing Customs or do not comply with the supplier’s terms and conditions of the contract.
                                                <br></br>
                                                • All claims must be made before the goods are returned or destroyed. 
                                                <br></br>
                                                • All claims must be made within 03 months of the original entry to Customs. 
                                                <br></br>
                                                Please note: FedEx will require any duties and taxes to be paid in full and you will need to process a claim directly with Customs</p>
                                        </ul>
                                        <br></br>
                                        
                                        </div>                                 
                                    </div>                          
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    <Footer/>
    </React.Fragment>
    );
  }
  
  export default DutiesandTaxesFaq;