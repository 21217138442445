import Header from "../components/Header";
import Footer from "../components/Footer";
import { useForm } from "react-hook-form";
import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { API_ROUTES, APP_ROUTES } from "../utils/constants";
import axios from "axios";
import Moment from "moment";
import { useNavigate } from "react-router-dom";


const Banner = () => {
  // add track components start

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [track, setTrack] = useState([]);
  const [step, setStep] = useState("stage1");
  const [showMore, setShowMore] = useState(false);

  const navigate = useNavigate();

  const onSubmit = async (form_data) => {
      const { data } = await axios({
        method: "get",
        url: API_ROUTES.TRACK + form_data.track_id,
      });
      navigate(`/track?track_id=${form_data.track_id}`);

      console.log(data?.meta?.status);
      if (data.meta?.status) {
        setTrack(data.data);
        console.log(data?.data);
        setStep("stage2");
      }
    
  };

  const more = async () => {
    setShowMore(true);
  };

  // add track components end

  return (
    <div className="home-bannerwrap">
      <div className="container-fluid">
        <div className="row no-gutters">
          <div className="col-md-12">
            <div className="hm-banner-wrap">
              <img src="assets/img/mainbanner.jpg" alt="" />
            </div>
            <div className="banner-contects">
              <h1>Ship, Manage, Track, Deliver</h1>
              <div className="search-tab-wrap">
                <ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active rate"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#rate"
                      type="button"
                      role="tab" aria-controls="pills-home" aria-selected="true"
                    >
                      <a href="/shipping-rates">
                        <img src="assets/img/rate-tab.png" alt="" />
                      </a>
                      RATE & SHIP
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link track"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#track"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      <img src="assets/img/track-tab.png" alt="" />
                      TRACK
                    </button>
                  </li>
                </ul>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="rate"
                      role="tabpanel"
                    >
                      <div className="row g-1">
                        <div className="col-md-8">
                          <input
                            type="text"
                            class="form-control"
                            {...register("track_id", {
                              required: "Tracking Number is required",
                            })}
                            id="track"
                            placeholder="TRACKING ID"
                          />

                          {errors.track?.message && (
                            <small class="text-danger">
                              {errors.track.message}
                            </small>
                          )}
                        </div>
                        <div className="col-lg-3 col-md-4">
                          <button
                            type="submit"
                            className="btn track-id mb-3"
                            id="show_transit"
                          >
                            TRACK
                          </button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12  text-center">
                          <div className="needhelp">
                            Need help? Contact our 
                            <a href="/customer-support" className="pl-2">Customer Support</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="track" role="tabpanel">
                      <div className="row g-1">
                        <div className="col-md-8">
                          <input
                            type="text"
                            className="form-control tracking"
                            id="inputPassword2"
                            placeholder="TRACKING ID"
                          />
                        </div>
                        <div className="col-lg-3 col-md-4">
                          <button type="submit" className="btn track-id mb-3">
                            TRACK
                          </button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12  text-center">
                          <div className="needhelp">
                            Need help ? Contact our
                            <a href="customer-support">Customer Support</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
