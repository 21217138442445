// src/components/GoogleAnalytics.js
import React, { useEffect } from 'react';

const GoogleAnalytics = () => {
  useEffect(() => {
    // Check if the script already exists
    const existingScript = document.getElementById('google-analytics-script');
    if (existingScript) return;

    // Create a script element
    const script = document.createElement('script');
    script.id = 'google-analytics-script';
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-KLC0KGMKWH';
    document.head.appendChild(script);

    // Initialize Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-KLC0KGMKWH');
  }, []);

  return null;
};

export default GoogleAnalytics;
