import Header from "../components/Header";
import Footer from "../components/Footer";
import React from 'react';

const SecurityPrivacy = () => {
    return (
      <React.Fragment>
        <Header name={{ name: "Advantis Express", group: "home" }} />
        <div className="contentswrap">
          <section
            className="banner-wrapper"
            style={{
              backgroundImage: `url(${"assets/img/aboutus-banner.png"})`,
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="banner-title">
                    <h1> Privacy Policy </h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="breadcrumb-wrap bann">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="#">HOME</a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                    Privacy Policy
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
              <div className="col-md-12">
                <div className="sub-titles about" data-aos="fade-down">
                  <h1>
                  Our Privacy Policy
                    <span className="rmark">
                      <sup>®</sup>
                    </span>
                  </h1>
                  <p>
                <b> Information Collection: </b> We capture visitor data including date/time of visit, referring address, browser type, and IP address for website analytics. Email addresses are not collected.
                  <br/>
                  <b> Personal Information: </b> Certain areas of advantisexpress.com require personal information such as name, address, email, and billing details for specific services. This information helps us tailor content, improve services, and send updates and marketing communications.
                  <br/>
                  <b> Use of Information: </b> Collected information helps us tailor content, improve services, and send updates and marketing communications. We do not sell or distribute information to third parties.   
                  <br/>
                  <b> External Links: </b> When visiting linked websites from advantisexpress.com, only referral information is shared with the third-party site, preserving your privacy. Advantis Express is not responsible for third-party privacy policies.
                  <br/>
                   <b> Cookies: </b> Cookies are data placed by a website onto a user's browser to recognize them. We use cookies for personalization and to associate users with their profiles.
                   <br/>
                 <b>  Cookie Management: </b> Users can remove cookies from their hard drive or adjust browser settings to disable cookies. Refer to your browser provider for guidance.
                 </p>
                </div>
              </div>
            </div>
          </section>
       
          {/* <section className="additional">
            <div className="container">
               <div className="row">
                  <div className="col-md-4">
                     <div className="additional-box" data-aos="fade-down">
                        <div className="addimage">
                           <img src="assets/img/wu-1.png" alt=""></img>   
                        </div>
                        <h4>Business & <br></br>E-Commerce</h4>
                        <a href="" className="btn-border">Grow your business</a>
                     </div>
                  </div>
                  <div className="col-md-4">
                     <div className="additional-box" data-aos="fade-down">
                        <div className="addimage">
                           <img src="assets/img/wu-2.png" alt=""></img>   
                        </div>
                        <h4>Ship & Save up to 45% <br></br>for 6 months</h4>
                        <a href="" className="btn-border">Open an account</a>
                     </div>
                  </div>
                  <div className="col-md-4">
                     <div className="additional-box" data-aos="fade-down">
                        <div className="addimage">
                           <img src="assets/img/wu-3.png" alt=""></img>   
                        </div>
                        <h4>International Shipping<br></br> Expertise</h4>
                        <a href="" className="btn-border">Find out more</a>
                     </div>
                  </div>
                  <div className="col-md-12" data-aos="fade-down">
                     <p className="term">*Some restrictions apply. See the Terms and Conditions section of the FedEx Service Guide and visit fedex.ca for details.</p>
                  </div>
               </div>
            </div>
         </section> */}
        </div>
        <Footer />
      </React.Fragment>
    );
  }
  
  export default SecurityPrivacy;
  