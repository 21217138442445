import Header from "../components/Header";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import GoogleAnalytics from '../components/GoogleAnalytics';
 
import React , { useEffect } from 'react';

const Home = () => {
  return (
    <React.Fragment>
      <Header name={{ name: "Advantis Express", group: "home" }} />
      <GoogleAnalytics />
      <Banner />
      <div className="contentswrap">
        
        <section className="shipments">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="shipment-title">
                  <h2>Manage my shipments and returns</h2>
                </div>
              </div>
              <div className="col-md-12">
                <div className="shipment-box" data-aos="fade-down">
                  <a
                    href="assets/pdf/Duty and taxes - home page.pdf"
                    className="shipment-box-wrap"
                  >
                    <img src="assets/img/s1.jpg" alt="" />
                    <h2>Pay Charges Including. Duty & Tax</h2>
                  </a>
                  {/* <a href="/schedule-pickup" className="shipment-box-wrap">
                    <img src="assets/img/s2.jpg" alt="" />
                    <h2>Manage My Deliveries</h2>
                  </a> */}
                  <a href="/contact-us" className="shipment-box-wrap">
                    <img src="assets/img/s3.jpg" alt="" />
                    <h2>Find Locations</h2>
                  </a>
                  <a
                    href="https://www.fedex.com/en-in/about/holiday-schedule.html"
                    className="shipment-box-wrap"
                  >
                    <img src="assets/img/s4.jpg" alt="" />
                    <h2>See Holiday Shipping Deadlines</h2>
                  </a>
                  <a href="/faq" className="shipment-box-wrap">
                    <img src="assets/img/s5.jpg" alt="" />
                    <h2>Frequently Asked Questions</h2>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="intro">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="intro-text cb-col-text" data-aos="fade-down">
                <h4>
                      SEND A DOCUMENT AND ENJOY SAVINGS OF UP TO 50%.
                </h4>
                  <h3 style={{ color: "#4D148C" }}>
                  Experience savings and accelerated delivery with FedEx
                    Document Express: Unlock a 50% discount on rates
                  </h3>
             
                  <h5>
                   
                  </h5>
                  <br></br>
                  <p>
                    We are taking our commitment to exceptional service a step
                    further by providing you with the opportunity to save
                    significantly on your document shipments – up to 50% off
                    on rates. Whether it's urgent contracts,
                  </p>
                  <p>
                    legal documents, or crucial business correspondence, we're
                    here to ensure that your documents reach their destination
                    swiftly and securely. Seize this limited-time offer and
                    discover the FedEx difference– where savings and
                    efficiency converge without compromise. Experience the
                    convenience, speed, and reliability of FedEx document
                    shipping while enjoying significant cost savings.
                  </p>
                  {/* <a href="/contact-us" className="learnmore"> </a> */}
                  <a className="btn-learn-more" href="/contact-us">
                    Call Us Now
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="intro-image" data-aos="fade-down">
                  <img
                    src="assets/img/poster.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="hm-customers">
          <div className="container">
            <div className="col-md-12">
              {/* <div className="customers-intro text-center" data-aos="fade-down">
                      <h3>When you and your customers need it most</h3>
                      <p>Global efforts to stop the spread of COVID-19 have changed the world—and the way you do business—overnight. We’re here to help your business deliver when you and your customers need it most.</p>
                  </div> */}
              <div className="col-md-12">
                <div className="customer-box">
                  <div className="row">
                    <div className="col-lg-7 col-md-12">
                      <div className="cb-col-image" data-aos="fade-down">
                        <br></br>
                        <img
                          src="assets/img/E-commerce-Solutions-Today.jpg"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-12">
                      <div className="cb-col-text" data-aos="fade-down">
                        <h4>
                          Get Started with FedEx E-commerce Solutions Today
                        </h4>
                        <p>
                          Experience the future of logistics with FedEx –
                          where your success is our priority. Unlock the full
                          potential of your online business with FedEx
                          E-commerce solutions. Our comprehensive range of
                          services, global network, and commitment to
                          excellence make us the partner of choice for
                          businesses looking to thrive in the digital
                          marketplace. Contact us today to discuss how we can
                          tailor our solutions to meet your unique e-commerce
                          needs. Our comprehensive suite of services help
                          businesses grow, streamline operations, and enhance
                          customer satisfaction.
                        </p>
                        <a href="/customer-support" className="learnmore">
                          Contact Us
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-5 col-md-12 order1">
                      <div className="cb-col-text" data-aos="fade-down">
                        <h4>Ship boxes of all shapes and sizes</h4>
                        <p>
                          Need to send a large or oddly shaped item like
                          bedding, clothing, a bicycle, or a monitor to a
                          campus? Experience the ease and convenience of
                          shipping with FedEx. No matter the size, shape, or
                          destination of your packages, we're here to make
                          your shipping experience smooth and worry-free.
                          Trust us to handle your shipments with care and
                          precision. Contact us today to explore our range of
                          shipping options and take the next step toward a
                          hassle-free shipping experience. At FedEx, we're not
                          just shipping packages – we're delivering peace of
                          mind.
                        </p>
                        {/* <a href="/order-details" className="learnmore">
                          Order Supplies
                        </a> */}
                      </div>
                    </div>
                    <div className="col-lg-7 col-md-12 order2">
                      <div className="cb-col-image" data-aos="fade-down">
                        <br></br>
                        <img
                          src="assets/img/shapes-and-sizes.jpg"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-7 col-md-12">
                      <div className="cb-col-image" data-aos="fade-down">
                        <img
                          src="assets/img/Shipping-Solutions.jpg"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-12">
                      <div className="cb-col-text" data-aos="fade-down">
                        <h4>
                          Lead the Way with FedEx Sustainable Shipping
                          Solutions
                        </h4>
                        <p>
                          Make a difference with every shipment by choosing
                          FedEx's Sustainable Shipping Solutions. With our
                          advanced technology, global network, and unwavering
                          commitment to environmental responsibility, we're
                          ready to help you navigate the path toward
                          sustainable shipping practices. Join us in creating
                          a world where commerce and conservation coexist
                          harmoniously.
                        </p>
                        <a
                          href="https://www.fedex.com/en-us/carbon-footprint-insights.html"
                          className="learnmore"
                        >
                          Learn more
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="hmabout">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="hmabout-title" data-aos="fade-down">
                  <h5>What you should know about</h5>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <a href="/register">
                  <div className="hmabout-box" data-aos="fade-down">
                    <img src="assets/img/account.svg" alt="" />
                    <h5>Opening/Managing FedEx Account</h5>
                  </div>
                </a>
              </div>
              <div className="col-md-3">
                <a href="/shipping-rates">
                  <div className="hmabout-box" data-aos="fade-down">
                    <img src="assets/img/delivery-time.svg" alt="" />
                    <h5>Shipping Rates & Delivery Times</h5>
                  </div>
                </a>
              </div>
              <div className="col-md-3">
                <a href="/customer-support">
                  <div className="hmabout-box" data-aos="fade-down">
                    <img src="assets/img/online-chat.svg" alt="" />
                    <h5>24/7 Customer Support</h5>
                  </div>
                </a>
              </div>
              <div className="col-md-3">
                <a href="https://www.youtube.com/watch?v=23-b0n5LsOk">
                  <div className="hmabout-box" data-aos="fade-down">
                    <img src="assets/img/packages.svg" alt="" />
                    <h5>Learning How To Pack Your Package</h5>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="manageorder">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="mobileui">
                  <img
                    src="assets/img/phone.png"
                    alt=""
                    className="screen1"
                    data-aos="fade-down"
                    data-aos-delay="300"
                  />
                  <img
                    src="assets/img/phone2.png"
                    alt=""
                    className="screen2"
                    data-aos="fade-down"
                    data-aos-delay="600"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="manageorder-contents" data-aos="fade-down">
                  <h4> Efficiency at Your Fingertips </h4>
                  <h5>
                    Advantis Express Rate Calculator: Instant
                    Rates, Real-Time Tracking
                  </h5>
                  <small>
                    No more waiting for shipping quotes.
                    With the Advantis Express Rate
                    Calculator, get instant access to accurate
                    shipping rates and real-time tracking for
                    your shipments. Get the information you
                    need to plan your shipments effectively.
                    Join the ranks of businesses who trust
                    Advantis Express for their shipping needs
                    and experience a new level of control and
                    convenience.
                  </small>
                  <br></br>
                  <br></br>
                  <a href="https://apps.apple.com/dk/app/mhel-rate-cal/id1473012882">
                    <img src="assets/img/app-store.png" alt="" />
                  </a>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <a href="https://play.google.com/store/apps/details?id=io.gayan.mhelexpress">
                    <img src="assets/img/game.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="newsroom">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="newsroom-title text-center"
                  data-aos="fade-down"
                >
                  <h5>Advantis Express News Room</h5>
                  <p>
                    Stay connected with our latest service updates, regulatory
                    changes and other important notifications.
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="newsroom-image" data-aos="fade-down">
                      <br></br>
                      <br></br>

                      <img src="assets/img/newsroom.jpg" alt="" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="newsroom-links-wrap" data-aos="fade-down">
                      <a href="">
                        <h6>
                          FedEx Launches FedEx® Sustainability <br></br>
                          Insights for Customer Emissions Tracking
                        </h6>
                      </a>
                      <p>
                        A revolutionary tool that utilizes actual network scan
                        data as the foundation for emissions reporting,
                        accompanied by a new API for shippers
                      </p>
                      <a
                        href="https://newsroom.fedex.com/newsroom/global-english/fedex-launches-fedex-sustainability-insights-for-customer-emissions-tracking"
                        className="newsroom-link"
                      ></a>
                    </div>
                    <div className="newsroom-links-wrap" data-aos="fade-down">
                      <a href="">
                        <h6>
                          FedEx and St. Jude Children’s Research <br></br>
                          Hospital celebrating 11th Purple Eagle honoree
                        </h6>
                      </a>
                      <p>
                        FedEx Corp. (NYSE: FDX), together with St. Jude
                        Children’s Research Hospital®, celebrated the 11th
                        anniversary of the Purple Eagle programme at TPC
                        Southwind during the FedEx St. Jude Championship.
                      </p>
                      <a
                        href="https://newsroom.fedex.com/newsroom/global-english/fedex-and-st-jude-childrens-research-hospital-celebrating-11th-purple-eagle-honoree"
                        className="newsroom-link"
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="placeorder">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-12">
                <div className="placeorder-text" data-aos="fade-down">
                  <h3>
                    Need help with <br />
                    placing your order?
                  </h3>
                  <p>
                    At FedEx, we recognise the importance of a seamless
                    ordering process is essential for your business. Our
                    dedicated team of experts are here to assist you every
                    step of the way , offering you guidance , answering your
                    questions, and ensuring that your shipments stay on
                    course. With our user-friendly online platform and
                    experienced support staff, you can rely on FedEx to
                    simplify and enhance your shipping experience, making it
                    more efficient and hassle free.
                  </p>
                  <a href="/customer-support" className="btn-learn-more">
                    Contact Support Now
                  </a>
                </div>
              </div>
              <div className="col-lg-7 col-md-12">
                <div className="placeorder-image" data-aos="fade-down">
                  <img
                    src="assets/img/placing-your-order.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default Home;

 